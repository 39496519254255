/*eslint-disable */
define(function (require) {

	if (!window.Modernizr) {
		window.Modernizr = require('modernizr');
	}

    // This object is populated in footer-requirejs.vm.
	wahanda.pageConfig.isTouchDevice = Modernizr.touch;

	return wahanda.pageConfig;
})
